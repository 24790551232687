import React, { useState, useEffect, useCallback } from 'react'

function App() {
  const [isTic, setTic] = useState(false)

  const ticking = useCallback(() => {
    setInterval(() => {
      setTic(!isTic)
    }, 5e3)
  }, [setTic, isTic])

  useEffect(() => {
    ticking()
  }, [ticking])

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <div className="txt-container NOMEANSNOGX">
        <span className="txt tic">n</span>
        <span className="txt tac">m</span>
        <span className="txt tic">n</span>
      </div>
    </div>
  )
}

export default App
